<template>
  <div class="address-page">
    <van-form @submit="onSubmit">
      <van-field
        v-model="detail.account"
        name="account"
        :label="PAGELANG.收款卡號"
        :placeholder="PAGELANG.请输入收款卡號"
        :rules="[{ required: true, message: PAGELANG.收款卡號不能为空 }]"
      />
      <van-field
	    is-link
	    readonly
        v-model="detail.bankName"
        name="bankName"
        :label="PAGELANG.开户行"
        :placeholder="PAGELANG.请输入开户行"
		 @click="showBank = true"
        :rules="[{ required: true, message: PAGELANG.开户行不能为空 }]"
      />
   
	  <van-popup v-model:show="showBank" round position="bottom">
	     <van-picker
	       :columns="bankperfix"
	       v-model="selectedlang"
	       @cancel="showBank = false"
	       @confirm="onConfirmBank"
	       :title="PAGELANG.请选择地区"
	  	   :columns-field-names="{ text: 'bankName' ,value:'bankId' }"
	  	   defaultIndex=2
	     />
	   </van-popup>
	  
	  <van-field
        v-model="detail.name"
        name="name"
        :label="PAGELANG.开户人姓名"
        :placeholder="PAGELANG.请输入开户人姓名"
        :rules="[{ required: true, message: PAGELANG.开户人姓名不能为空 }]"
      />
	  
	  
	  <van-field
	    is-link
	    readonly
	    v-model="detail.phoneArea"
		name="phoneArea"
	   :label="PAGELANG.手機區號"
	   :placeholder="PAGELANG.请输入手機區號"
	    @click="showPrefix = true"
		:rules="[{ required: true, message:PAGELANG.手機區號不能为空 }]"
	  />
       <van-popup v-model:show="showPrefix" round position="bottom">
          <van-picker
            :columns="mobileperfix"
            v-model="selectedlang"
            @cancel="showPrefix = false"
            @confirm="onConfirm"
            :title="PAGELANG.请选择地区"
			:columns-field-names="{ text: 'countryName' ,value:'countryNum' }"
			defaultIndex=2
          />
        </van-popup> 
		
		<van-field
			v-model="detail.bankReservedPhone"
			name="bankReservedPhone"
			:label="PAGELANG.銀行預留手機號"
			:placeholder="PAGELANG.请输入銀行預留手機號"
			:rules="[{ required: true, message: PAGELANG.銀行預留手機號不能为空 }]"
		  />
	  

      <div class="add-btn-block">
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          >{{ SYSLANG.save }}</van-button
        >
      </div>
    </van-form>

    <van-button
      v-if="accountid > 0"
      plain
      square
      block
      type="primary"
      class="btn-delete"
      @click="onDelete"
      >{{ SYSLANG.delete }}</van-button
    >
  </div>
</template>

<script>
import {
  closeToast,
  showConfirmDialog,
  showLoadingToast,
  showToast,
} from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      accountid: 0,
      detail: {},
	  curPerfix: "中國台灣",
	  showPrefix:false,
	  showBank:false,
	  bankId:0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	this.langcode = getCookie("lh_aog_langcode");
	this.axios.get(this.actions.area_prefix).then((response) => {
	  this.mobileperfix = response.data.data;
	  let langitem = this.mobileperfix.find((item) => {
	    if (item.value == this.langcode) return true;
	    return false;
	  });
	  console.log("this.mobileperfix",this.mobileperfix)
	  if (langitem) {
	    this.selectedlang = [langitem.value];
	    this.curPerfix = langitem.text;
	  }
	  console.log()
	});
	
	
	this.axios.get(this.actions.commonBanks).then((response) => {
	  this.bankperfix = response.data.data;
	  let langitem = this.bankperfix.find((item) => {
	    if (item.value == this.langcode) return true;
	    return false;
	  });
	  console.log("this.bankperfix",this.bankperfix)
	  if (langitem) {
	    this.selectedlang = [langitem.value];
	    this.curPerfix = langitem.text;
	  }
	  console.log()
	});
	

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.income;
    this.getAccount();
    // if (this.$route.query.accountid) {
    //   this.accountid = this.$route.query.accountid;
    //   if (!isNaN(this.accountid) && this.accountid > 0) {
    //     this.getAccount();
    //   }
    // }
  },
  methods: {
	onConfirm({ selectedOptions }) {
	  console.log('selectedOptions',selectedOptions);
	  this.showPrefix = false;
	  this.detail.phoneArea = selectedOptions[0].countryName;
	  this.areaPhone=selectedOptions[0].countryNum;
	  //setCookie("lh_aog_langcode", selectedOptions[0].value);
	  //location.reload();
	},
	onConfirmBank({ selectedOptions }) {
	  console.log('selectedOptions',selectedOptions);
	  this.showBank = false;
	  this.detail.bankName = selectedOptions[0].bankName;
	  this.bankId = selectedOptions[0].bankId;
	  //this.showPrefix = false;
	  //this.detail.phoneArea = selectedOptions[0].countryName;
	  //this.areaPhone=selectedOptions[0].countryNum;
	  //setCookie("lh_aog_langcode", selectedOptions[0].value);
	  //location.reload();
	},  	  
	  
    getAccount() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
	  //获取账户信息
	  this.axios.get(this.actions.accountsList,{params:{type:2}}).then((res) => {
	     closeToast();
	  		 let code = res.data.code;
	  		 if(code==200){
	  			if(res.data.data[0].account==""){
	  				this.isAdd=true
	  				}else{
	  				this.detail=(res.data.data[0]);
					this.bankId=res.data.data[0].bankId
	  			}
	  		 }
	  });
	  //获取个人信息
	  this.axios.get(this.actions.memberInfo).then((response) => {
	    closeToast();
	   let { code, message, data } = response.data;
	    if (code == "200") {
	      this.agentInfo = data;						
	    } else {
	      showToast({
	        message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
	        forbidClick: true,
	        onClose: () => {
	          if (code == "NOTLOGIN") {
	            this.$router.replace({
	              path: "/login",
	            });
	          }
	        },
	      });
	    }
	  });

      // this.axios
      //   .get(this.actions.income_account_detail + this.accountid)
      //   .then(({ data }) => {
      //     closeToast();
      //     let { code, msg, detail } = data;
      //     console.log(code, msg, detail);
      //     if (code != "SUCCESS") {
      //       showToast({
      //         message:
      //           code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
      //         forbidClick: true,
      //         onClose: () => {
      //           if (code == "NOTLOGIN") {
      //             this.$router.replace({
      //               path: "/login",
      //             });
      //           }
      //         },
      //       });
      //     } else if (detail) {
      //       this.detail = detail;
      //     } else {
      //       this.accountid = 0;
      //     }
      //   })
      //   .catch((error) => {
      //     closeToast();
      //     console.log("views.IncomeAccountEdit.getAccount.error", error);
      //     this.getAccount();
      //   });
    },
    onSubmit(postdata) {
	  // if(this.agentInfo.realName!=postdata.name){
		 //  showToast({
			//    message:'收款人名称与商城姓名不符,必須本人打款'
		 //  })
		 //  return
	  // }
	  postdata.bankId=this.bankId;
	  postdata.exchangeMoneyType="TWD";
	  postdata.exchangeMoneyName="新台币TWD";
	  postdata.idCard="";
	  postdata.idCardType=2;
	  postdata.idType=1;
	  postdata.type=1;
	  postdata.exchangeAddress="";
	  postdata.exchangeArea="";
	  postdata.exchangeCountry="";
	  postdata.exchangeSwift="";
	  postdata.bankReservedPhone=this.areaPhone+postdata.bankReservedPhone;
	  console.log("submit", postdata);
	  
	  showLoadingToast({
	    message: this.SYSLANG.正在保存,
	    duration: 0,
	    forbidClick: true,
	  });
	  
	  this.axios.post(this.actions.accountsList,postdata).then((res)=>{
		   closeToast();
		   if(res.data.code==200){
			   this.$router.push("/income");
		   }else{
			  showToast({
				  message:res.data.message
			  }) 
		   }
	  })
	  
	  return;
      // postdata.accountid = this.accountid;

      // this.axios
      //   .post(this.actions.income_account_save, postdata)
      //   .then(({ data }) => {
      //     closeToast();
      //     let { code, msg } = data;
      //     showToast({
      //       message:
      //         code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
      //       forbidClick: true,
      //       onClose: () => {
      //         if (code == "SUCCESS") {
      //           this.$router.back();
      //         }
      //       },
      //     });
      //   });
    },
    onDelete() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要删除该收款账户吗,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.income_account_delete + this.accountid)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.$router.back();
                  }
                },
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style scoped>
.address-page {
  padding: 16px 0 var(--van-submit-bar-height) 0;
  --van-cell-vertical-padding: 14px;
}

.address-page >>> .van-cell::after {
  left: calc(var(--van-padding-md) + var(--van-field-label-width));
}

.btn-delete {
  --van-button-radius: 0;
  --van-button-primary-border-color: #fff;
  --van-button-primary-background: #fe295f;
  --van-button-default-height: var(--van-submit-bar-button-height);
  --van-button-normal-font-size: var(--van-submit-bar-font-size);
  margin-top: 16px;
}
</style>
